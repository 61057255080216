<template>
    <v-main style="min-height: 80% !important;" >
        <v-container class="pb-12">
            <v-skeleton-loader
                v-if="product.loadingProducts"
                v-bind="attrs"
                height="100%"
                width="100%"
                type="article, list-item-three-line, products"
                :types="{products: 'card@4'}"
                elevation="0"
            ></v-skeleton-loader>
            <template v-else>
                <v-row class="pt-5" v-if="this.$route.query.search">
                    <v-col cols="12">
                        <h2 :style="{ color: parameters.primaryColor }" class="text-left">
                            Você pesquisou por: "{{ $route.query.search }}"
                        </h2>
                    </v-col>
                </v-row>
                <template v-if="product.filteredProducts != null && product.filteredProducts.products && product.filteredProducts.products.length > 0">
                    <ProductsFilters v-if="$mq == 'lg'"/>
                    <ProductsFiltersMobile v-else/>
                    <AppliedFilters />
                </template>
                <v-row v-if="product.filteredProducts == null || !product.filteredProducts.products || product.filteredProducts.products.length == 0" class="pt-6">
                    <v-col cols="12">
                        <p class="headline" :style="{color: parameters.primaryColor}">Não foi possível encontrar nenhum produto com os parâmetros informados ☹️</p>
                    </v-col>
                </v-row>
                <ProductsList v-else/>
            </template>
        </v-container>
    </v-main>
</template>

<script>
const ProductsFilters = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/ProductsFilters.vue"
    );
const ProductsFiltersMobile = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/ProductsFiltersMobile.vue"
    );
const AppliedFilters = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/AppliedFilters.vue"
    );
const ProductsList = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/ProductsList.vue"
    );

export default {
    data: () => ({
        attrs: {
            class: 'mb-6',
            tile: true,
            elevation: 2,
        },
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        product: {
            get() {
                return this.$store.state.product;
            },
        },
        colsForProducts(){
            if (this.$mq == "lg")
                return 4
            if (this.$mq == "md")
                return 6
            return 12
        }
    },
    components: {
        ProductsFilters,
        AppliedFilters,
        ProductsFiltersMobile,
        ProductsList,
    },
    methods:{
        search(model){
            this.$store.dispatch("product/search", model)
        }
    },
    mounted(){
        this.search({
            search: this.$route.query.search,
            groups: this.$route.query.groups,
            collections: this.$route.query.collections,
            colors: this.$route.query.colors,
            sizes: this.$route.query.sizes,
        })
    },
    beforeRouteUpdate(to, from, next){
        this.search({
            search: to.query.search,
            groups: to.query.groups,
            collections: to.query.collections,
            colors: to.query.colors,
            sizes: to.query.sizes,
        })
        next()
    }
};
</script>

<style>
</style>