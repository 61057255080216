var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":_vm.$mq != 'lg' ? 12 : 4}},[_c('v-select',{attrs:{"items":_vm.product.filteredProducts.groups,"item-text":"prsnome","hide-details":"","label":"Categorias","multiple":"","no-data-text":"Nenhuma categoria","item-value":"prsgrupo","color":_vm.parameters.secondaryColor,"item-color":_vm.parameters.secondaryColor},on:{"change":_vm.changeFilters},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"dark":"","color":_vm.parameters.secondaryColor}},[_c('span',[_vm._v(_vm._s(item.prsnome))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.product.selectedGroups.length - 1)+" outros) ")]):_vm._e()]}}]),model:{value:(_vm.product.selectedGroups),callback:function ($$v) {_vm.$set(_vm.product, "selectedGroups", $$v)},expression:"product.selectedGroups"}})],1),_c('v-col',{attrs:{"cols":_vm.$mq != 'lg' ? 12 : 4}},[_c('v-select',{attrs:{"items":_vm.product.filteredProducts.colors,"item-text":"cr1nome","label":"Cores","hide-details":"","multiple":"","no-data-text":"Nenhuma cor","item-value":"pr2opcao","color":_vm.parameters.secondaryColor,"item-color":_vm.parameters.secondaryColor},on:{"change":_vm.changeFilters},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"dark":"","color":_vm.parameters.secondaryColor}},[_c('span',[_vm._v(_vm._s(item.cr1nome))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.product.selectedColors.length - 1)+" outros) ")]):_vm._e()]}}]),model:{value:(_vm.product.selectedColors),callback:function ($$v) {_vm.$set(_vm.product, "selectedColors", $$v)},expression:"product.selectedColors"}})],1),_c('v-col',{attrs:{"cols":_vm.$mq != 'lg' ? 12 : 4}},[_c('v-select',{attrs:{"items":_vm.product.filteredProducts.sizes,"item-text":"gr1desc","label":"Tamanhos","no-data-text":"Nenhum tamanho","item-value":"pr3tamanho","multiple":"","hide-details":"","color":_vm.parameters.secondaryColor,"item-color":_vm.parameters.secondaryColor},on:{"change":_vm.changeFilters},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"dark":"","color":_vm.parameters.secondaryColor}},[_c('span',[_vm._v(_vm._s(item.gr1desc))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.product.selectedSizes.length - 1)+" outros) ")]):_vm._e()]}}]),model:{value:(_vm.product.selectedSizes),callback:function ($$v) {_vm.$set(_vm.product, "selectedSizes", $$v)},expression:"product.selectedSizes"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }