<template>
    <v-row class="px-3">
        <v-col
            v-for="pr0 in product.filteredProducts.products"
            :key="pr0.pr0produto"
            :cols="colsForProducts"
            class="px-1"
        >
            <ProductCard :product="pr0" classCard="productCardSearch"/>
        </v-col>
    </v-row>
</template>

<script>
const ProductCard = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/ProductCard.vue"
    );

export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        product: {
            get() {
                return this.$store.state.product;
            },
        },
        colsForProducts(){
            if (this.$mq == "lg")
                return 3
            if (this.$mq == "md")
                return 4
            return 6
        },
    },
    components:{
        ProductCard
    }
};
</script>

<style>
</style>