<template>
    <v-row>
        <v-col :cols="$mq != 'lg' ? 12 : 4">
            <v-select
                v-model="product.selectedGroups"
                :items="product.filteredProducts.groups"
                item-text="prsnome"
                hide-details
                label="Categorias"
                multiple
                no-data-text="Nenhuma categoria"
                item-value="prsgrupo"
                :color="parameters.secondaryColor"
                :item-color="parameters.secondaryColor"
                @change="changeFilters"
            >
                <template v-slot:selection="{ item, index }">
                    <v-chip dark :color="parameters.secondaryColor" v-if="index === 0">
                        <span>{{ item.prsnome }}</span>
                    </v-chip>
                    <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                    >
                      (+{{ product.selectedGroups.length - 1 }} outros)
                    </span>
                </template>
            </v-select>
        </v-col>
        <v-col :cols="$mq != 'lg' ? 12 : 4">
            <v-select
                v-model="product.selectedColors"
                :items="product.filteredProducts.colors"
                item-text="cr1nome"
                label="Cores"
                hide-details
                multiple
                no-data-text="Nenhuma cor"
                item-value="pr2opcao"
                :color="parameters.secondaryColor"
                :item-color="parameters.secondaryColor"
                @change="changeFilters"
            >
                <template v-slot:selection="{ item, index }">
                    <v-chip dark :color="parameters.secondaryColor" v-if="index === 0">
                        <span>{{ item.cr1nome }}</span>
                    </v-chip>
                    <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                    >
                      (+{{ product.selectedColors.length - 1 }} outros)
                    </span>
                </template>
            </v-select>
        </v-col>
        <v-col :cols="$mq != 'lg' ? 12 : 4">
            <v-select
                v-model="product.selectedSizes"
                :items="product.filteredProducts.sizes"
                item-text="gr1desc"
                label="Tamanhos"
                no-data-text="Nenhum tamanho"
                item-value="pr3tamanho"
                multiple
                hide-details
                :color="parameters.secondaryColor"
                :item-color="parameters.secondaryColor"
                @change="changeFilters"
            >
                <template v-slot:selection="{ item, index }">
                    <v-chip dark :color="parameters.secondaryColor" v-if="index === 0">
                        <span>{{ item.gr1desc }}</span>
                    </v-chip>
                    <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                    >
                      (+{{ product.selectedSizes.length - 1 }} outros)
                    </span>
                </template>
            </v-select>
        </v-col>
    </v-row>
</template>

<script>
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        product: {
            get() {
                return this.$store.state.product;
            },
        },
    },
    methods:{
        changeFilters(){
            let query = {
                search: this.$route.query.search,
                groups: this.product.selectedGroups,
                colors: this.product.selectedColors,
                collections: this.$route.query.collections,
                sizes: this.product.selectedSizes,
            }
            this.$router.push({path: "/pesquisa", query})
        }
    }
};
</script>

<style>
</style>