<template>
    <v-row>
        <v-col cols="12">
            <v-chip
                v-for="prsgrupo in product.selectedGroups"
                :key="`category-${prsgrupo}`"
                close
                close-icon="mdi-close"
                outlined
                label
                :color="parameters.primaryColor"
                class="ma-1"
                @click:close="removeGroup(prsgrupo)"
            >
                {{ groupName(prsgrupo) }}
            </v-chip>
            <v-chip
                v-for="colorId in product.selectedColors"
                :key="`color-${colorId}`"
                close
                close-icon="mdi-close"
                outlined
                label
                :color="parameters.primaryColor"
                class="ma-1"
                @click:close="removeColor(colorId)"
            >
                {{ colorName(colorId) }}
            </v-chip>
            <v-chip
                v-for="sizeId in product.selectedSizes"
                :key="`size-${sizeId}`"
                close
                close-icon="mdi-close"
                outlined
                label
                :color="parameters.primaryColor"
                class="ma-1"
                @click:close="removeSize(sizeId)"
            >
                {{ sizeName(sizeId) }}
            </v-chip>
        </v-col>
    </v-row>
</template>

<script>
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        product: {
            get() {
                return this.$store.state.product;
            },
        },
    },
    methods:{
        groupName(prsgrupo){
            const filterGroup = group => group.prsgrupo == prsgrupo
            return this.product.filteredProducts.groups.filter(filterGroup)[0].prsnome
        },
        colorName(colorId){
            const filterColor = color => color.pr2opcao == colorId
            return this.product.filteredProducts.colors.filter(filterColor)[0].cr1nome
        },
        sizeName(sizeId){
            const filterSize = size => size.pr3tamanho == sizeId
            return this.product.filteredProducts.sizes.filter(filterSize)[0].gr1desc
        },
        removeGroup(prsgrupo){
            let index = this.product.selectedGroups.findIndex(group => group == prsgrupo)
            this.product.selectedGroups.splice(index,1)
            this.search()
        },
        removeColor(colorId){
            let index = this.product.selectedColors.findIndex(color => color == colorId)
            this.product.selectedColors.splice(index,1)
            this.search()
        },
        removeSize(sizeId){
            let index = this.product.selectedSizes.findIndex(size => size == sizeId)
            this.product.selectedSizes.splice(index,1)
            this.search()
        },
        search(){
            let query = {
                search: this.$route.query.search,
                groups: this.product.selectedGroups,
                colors: this.product.selectedColors,
                collections: this.$route.query.collections,
                sizes: this.product.selectedSizes,
            }
            this.$router.push({path: "/pesquisa", query})
        }
    }
};
</script>

<style>
</style>