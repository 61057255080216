<template>
    <v-dialog
        transition="dialog-transition"
        v-model="product.showFilters"
        persistent
        fullscreen
    >
        <template v-slot:activator="{ on, attrs }">
            <v-row class="pt-6">
                <v-col cols="12">
                    <v-btn 
                        dark
                        rounded
                        :color="parameters.secondaryColor"
                        @click="product.showFilters = true"
                        v-on="on"
                        v-bind="attrs"
                    >
                        <v-icon>mdi-filter-variant</v-icon>
                        Filtros
                    </v-btn>
                </v-col>
            </v-row>
        </template>

        <v-card height="100%" width="100%" color="#FDFDFD">
            <v-toolbar dark dense color="#FDFDFD" class="subtitle-1" :style="{color: parameters.primaryColor}" elevation="0">
                <v-toolbar-title>Filtros</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon dark :color="parameters.primaryColor" draggable @click="product.showFilters = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <ProductsFilters />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
const ProductsFilters = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/ProductsFilters.vue"
    );
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        product: {
            get() {
                return this.$store.state.product;
            },
        },
    },
    components:{
        ProductsFilters
    }
}
</script>

<style>

</style>